import React, {useState, useEffect} from 'react';
import "./style.styl";

export default function ContentMarketingPage(props) {
    return (
        <div className="container draft-view">
           <h1>Content Marketing</h1>
           <p className="text-muted">Using content marketing, you increase brand exposure, become an expert in the eyes of your target audience, and as a result, increase your sales.</p>
           <p className="text-muted">Our agency helps you make great content marketing from strategy to implementation and measurement of effectiveness.</p>
           <p className="text-muted">Great content marketing is almost unfair. It brings qualified purchasers to you, helps drive conversions while providing brand lift, and continues to deliver value for years.&nbsp;</p>
           <p className="text-muted">But in a crowded digital space, not every piece of content achieves its full potential. Content success requires agility, strategy, a willingness to provide value, and above all, persistence.</p>
           <p className="text-muted">At Wescale, we create content that resonates with your customers because it’s engineered for them. Our content marketing services start with well-crafted strategies, drawing on channel expertise and performance data to make better content that works harder for your business.</p>
           <p className="text-muted"></p>
           <h2>Business tasks of content marketing</h2>
           <p className="text-muted">Content marketing one of the ways that can help you increase sales. Strengths of content marketing are abilities to humanizes your business, adds authority to the brand through content&nbsp;<span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">in a cost-effective way. In other words, you give people good information to connect with them, attract and transform them into clients.</span></p>
           <p className="text-muted">Content marketing is a cost-effective way but not cheap. Also, it takes time unlike the PPC (pay per click online advertising). On the other hand, content-marketing builds trust and rapport with the audience. The common practice to combine forms of marketing to get the best result.</p>
           <p className="text-muted">We keep your revenue top of mind to solve business challenges. Wescale provides full-service from content strategy, creation, publishing to distribution, and promotion.</p>
           <p className="text-muted"><br /></p>
           <h2>Important points for a good content strategy</h2>
           <ol>
              <li className="text-muted">First, we create for you a documented strategy. It is one way to make many things understandable to optimize efforts and resources.</li>
              <li className="text-muted">Second, we select the most appropriate way to implement the strategy. For example, expertise in a specific area. For example, some publications, articles, infographics that are not directly related to your product, but related to its area. People see that you are an expert here and therefore buy it from you.</li>
              <li className="text-muted">The third is a psychological nuance, when people get something from you for free and respond in the form of gratitude, they want to do some kind of response service. We should consider this nuance in the strategy.</li>
           </ol>
           <p className="text-muted"><br /></p>
           <h2>What to look for in a content marketer</h2>
           <p className="text-muted">Excellent writing and strong ideas are table stakes for content. Effective content marketers provide value by focusing on:</p>
           <ol>
              <li className="text-muted"><b>Collaboration:</b> Strong content marketing combines writing talent and business know-how. Your content marketing agency should be able to draw out your team’s expertise and frame it compellingly.</li>
              <li className="text-muted"><b>Data:</b> You don’t know exactly how content will perform until you put it out there, but your marketing partner needs to be ready to learn from the data when you do.</li>
              <li className="text-muted"><b>Amplification:</b> In a noisy world, the content has to be promoted to be seen. Behind your content creators, you need the marketing muscle to create space for your voice to be heard.</li>
           </ol>
           <p className="text-muted"></p>
           <p className="text-muted">Content can sell without pushing a product or service to the market but giving people the necessary information. Wescale is ready to offer the services of an experienced content marketing team and expand the capabilities of your business.<br /></p>

        </div>

    );
}
